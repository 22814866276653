.aboutme-container{
    padding: var(--page-padding);
    display: flex;
    gap: var(--gap);
    justify-content: center;
    align-items: center;   
}

.aboutme-container img{
    max-width: 50%;
    max-height: 300px;
    height: auto;
    border-radius:50%;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

}
.divclass h1{
    text-align: center;
    margin-bottom: 25px;
}