.chat-container{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.icon{
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 10px 20px;
}

.icon svg{
    width: 100px;
    height: auto;
    cursor: pointer;
}


.chat_box{
    position: relative;
    border: 3px solid var(--main-brand);
    border-radius: 10px;
    width: 300px;
    height: 400px;
    margin: 50px 50px;
    background-color: white;
}

.chat_box h1{
    width: 100%;
    font-size: 30px;
    text-align: center;
    border-bottom: 3px solid var(--main-brand);
    padding-bottom: 5px; 
}

.input_container{
    position: absolute;
    bottom: 0;
    width: 100%;
}


.input_message{
    width: 70%;
    height: 40px;
    border-bottom-left-radius: 10px;
    border: none;
    border-top: 3px solid var(--main-brand);
    font-size: inherit;
    padding-left: 10px;
}

.input_message:focus {
    outline: none;
}

.input_container button{
    width: 30%;
    height: 40px;
    border-bottom-right-radius: 10px;
    border: none;
    border-top: 3px solid var(--main-brand)
}

.signIn_container{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.signInButton{
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
        
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    margin-bottom: 70px;
}

.signInButton:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.signInButton:active {
    background-color: #eeeeee;
}

.signInButton:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.signInButton:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}




.header{
    border-bottom: 3px solid var(--main-brand);
    height: 30px;
}

.signOut{
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.signOut img{
    width: 25px;
    height: 25px;
}

.close_chat{
    position: absolute;
    right: 0px;
    border: none;
    background-color: transparent;
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-right: 5px;
}

.close_chat img{
    width: 25px;
    height: 25px;
}
.sendform {
    position: absolute;
    bottom: 1px;
    height: 45px;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    border-bottom-right-radius: 15px;
    align-items: center;
    background-color: #fff;
    padding: 10px 15px;
    border-top: 1px solid #eee;
}


.sendform button{
    width: 23%;
    background-color: var(--main-brand);
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
}

.sendform input{
    width: 77%;
    flex: 1;
    border: none;
    outline: none;
    padding: 8px 10px;
    border-radius: 20px;
}

.message {
    display: flex;
    align-items: center;
    border-radius: 15px;
    position: relative; 
}

.sent {
    flex-direction: row-reverse;
}
  
.sent p {
    color: white;
    background-color: #147efb;
    padding: 2px 10px 4px 10px;
    border-radius: 30px;
}
.recieved p {
    padding: 2px 10px 5px 10px;
    border-radius: 30px;
    background-color: #e7e7e7;
    ;
    color: black;

}

.accountImg{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 2px;  
}

.admin{
    width: 600px !important;
    height: 400px !important;
    min-width: 350px;
}

.user_display{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 365px;
    border-right: 3px solid var(--main-brand);
    overflow-y: auto;
}

.user_display::-webkit-scrollbar {
    width: 5px
}

.user_display::-webkit-scrollbar-track {
    background: white;
}
  
.user_display::-webkit-scrollbar-thumb {
    background: black;
    height: 30px;
}

.user_display button{
    padding: 10px 20px;
    background-color: transparent;
    border: 1px solid #eee;
    cursor: pointer;
}


.message_display{
    width: 75%;
    position: relative;
}

.main_message_container{
    display: flex;
}

.text_message_container{
    width: 100%;
    overflow-y: auto;
    height: 100%;
    max-height: 320px;
}

.text_message_container:last-child{
    padding-bottom: 10px;
}


.text_message_container::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
.text_message_container::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
.text_message_container::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.text_message_container::-webkit-scrollbar-button {
    display:none;
}