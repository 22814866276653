

.timeline{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}


.timeline_container{
    padding: 10px 50px;
    position: relative;
    width: 50%;
}

.timeline_container.active{
    animation: movedown 1s linear forwards;
    opacity: 0;
}

@keyframes movedown{
    0%{
        opacity: 1;
        transform: translateY(-30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}

.timeline_container:nth-child(1){
    animation-delay: 0s;
}
.timeline_container:nth-child(2){
    animation-delay: 1s;
}
.timeline_container:nth-child(3){
    animation-delay: 2s;
}


.timeline_text{
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
    font-size: 15px;
}

.right-container{
    left: 50%;
}

.left-container{
    left: 0;
}

.timeline_container img{
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: -20px;
    top: 32px;
    z-index: 10;
}

.right-container img{
    left: -20px;
}

.active.timeline::after{
    content: '';
    position: absolute;
    width: 6px;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -3px;
    background: white;
    animation: moveline 3s linear forwards;
}

@keyframes moveline{
    0%{
        height: 0;
    }
    100%{
        height: 100%;
    }
}

.timeline_text h2{
    font-weight: 600;
}

.timeline_text h3{
    font-weight: 400;
}

.timeline_text small{
    display: inline-block;
    margin-bottom: 15px;
}

.timeline_arrow.left-container{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid white;
    left: 100%;

}

.timeline_arrow.right-container{
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid white;
    left: -15px;
}

.timeline_section{
    width: 100%;
    height: 100%;
    padding: 100px 0;
    background-color: var(--dark-shades);
    margin-top: -2px;
}

@media screen and (max-width: 600px) {
    .timeline_section{
        padding: 50px 0;
    }
    .active.timeline::after{
        left: 31px;
    }
    .timeline_container{
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }
    .timeline_text{
        font-size: 13px;
    }
    .timeline_text small{
        margin-bottom: 10px;
    }
    .right-container{
        left: 0;
    }
    .left-container img, .right-container img{
        left: 10px;
    }
    .timeline_arrow.left-container, .timeline_arrow.right-container{
        border-right: 15px solid white;
        border-left: 0;
        left: -14px;
    }
}