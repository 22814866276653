.modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-shades);
}
.modal-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: var(--padding);
    border-radius: var(--border-radius);
    gap: var(--gap);
    background-color: var(--dark-accent);
    border: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 1);
    max-width: 40%;
}
.span2{
    grid-column: span 2;
}

.modal-container img{
    width: 100%;
    height: auto;
    max-height: 550px;
    object-fit: fill;
}

@media screen and (max-width: 1200px) {
    
    .modal-content{
        max-width: 80%;
    }
}