h1,
h2,
h3,
h4,
h5,
p{
    margin: 0;
}
/* colormind.io is a website that gives you many opetions of what colors look good together*/

*,
*::after,
*::before{
    /*this makes the box not include extra size then adding "padding" */
    box-sizing: border-box;
}
:root{
    --page-padding: 1.2rem 10rem; /* creates space bteween containers*/
    --gap: 1rem;
    --padding: 1.2rem;
    --border-radius: 5px;

    --light-shades: #ffffff;
    --main-brand: #1693ac;
    --dark-accent: #333;
    --dark-shades: #232824;
}

html{
    scroll-behavior: smooth;
}

.btn{
    padding: 0.45rem 1.2rem;
    cursor: pointer;
    background-color: var(--dark-shades);
    border-radius: var(--border-radius);
    color: var(--main-brand);
    border: none;
    transition: all 0.2s ease-in-out;
    width: fit-content;
}
.btn:hover{
    background-color: var(--main-brand);
    color: var(--light-shades);
}

.align-right{
    margin-left: auto;
}

.background-animation{
    background: linear-gradient(
        -45deg,
        var(--light-shades),
        var(--dark-shades),
        var(--dark-accent)
    );
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
}

@keyframes gradient{
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%; 
    }
}


@media screen and (max-width: 768px) {
    :root{
        --page-padding: 1rem 2rem;
    }
    .navbar-container{
        flex-direction: column;
        gap: var(--gap);
    }
    .navbar-links-container{
        flex-direction: column;
    }

    .aboutme-container{
        flex-direction: column;
    }

    .contact-container{
        flex-direction: column;
    }
}