.contact-container{
    display: flex;
    background-color: var(--main-brand);
    padding: var(--padding);
    justify-content: space-evenly;
    color: var(--light-shades);
    letter-spacing: 1px;
    font-size: 1.2rem;

}
