.portfolio-container{
    padding: var(--page-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
}
.portfolio-projects-frame img{
    width: 200px;
    height: 200px;
    object-fit: fill;
    border-radius: var(--border-radius);
    cursor: pointer;
}

#JeweleryWebsite{
    width: 250px;
}

#OmInternship{
    width: 280px;
}
.portfolio-projects-frame img:hover{
    filter: brightness(0.8);
}
.portfolio-grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--gap);
    justify-items: center;
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    /* text-decoration: underline; */
    margin-bottom: 5px; 
}


/* css for project grid */

.project-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
    
}

.project-grid{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-bottom: 30px;
    align-items: center;
}

.project-item{
    width: 500px;
    height: 250px;
    min-width: 500px;
    max-width: 500px;
    border-radius: var(--border-radius);
    background-color: var(--dark-shades);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    flex: 1;
    box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.1);
    border: 3px solid var(--dark-shades);;
}

.project-item:hover{
    border: 3px solid var(--main-brand);
}

.project-item h2{
    color: white;
}

.project-item p{
    text-align: center;
    color: white;
}


@media (max-width: 550px) {
    .project-grid{
        width: 90%;
        gap: 20px;
    }
    .project-item{
        height: auto; /* Adjust the height as needed */
        max-width: none;
        min-width: 350px;
    }

    
}