.technologies-container{
    padding: var(--page-padding);
    background-color: var(--dark-shades);
    color: var(--light-shades);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap);
}

.tech-grid-container{
    width: 100%;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--gap);
}

.tech-grid-container img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.tech-frames{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding);
    border-radius: var(--border-radius);
    border: 1px solid var(--main-brand);
}
